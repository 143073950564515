import { useState } from "react"
import { motion } from "framer-motion"
import { RiCloseLargeFill } from "react-icons/ri";

export const NovoCardPrecos = () => {
    const [isAnnual, setIsAnnual] = useState(false)

    const handlePlanChange = (isAnnualPlan: boolean) => {
        setIsAnnual(isAnnualPlan);
    };

    const plans = {
        monthly: {
            busca: {
                price: "69,99",
                period: "mensal",
                features: [
                    "Pesquisa de Editais",
                    "Agenda de Licitações Integrada",
                    "Sistema de Favoritos",
                    "Acompanhamento em Tempo Real",
                ],
            },
            premium: {
                price: "349,99",
                period: "mensal",
                features: [
                    "Pesquisa de Editais",
                    "Agenda de Licitações Integrada",
                    "Sistema de Favoritos",
                    "Acompanhamento em Tempo Real",
                    "Pesquisa de Editais em Múltiplos Portais",
                    "Alertas Diários Personalizados",
                    "Agenda de Licitações Integrada",
                    "Sistema de Favoritos com Notificações para Acompanhar Editais",
                    "Acompanhamento em Tempo Real",
                    "Gerenciamento de Documentos",
                    "Análise de Editais",
                    "Geração Automática de Declarações",
                    "Verificação de Documentos Necessários",
                    "Recomendações Personalizadas Avançadas",
                    "CPH Automático",
                ],
            },
            multiEmpresa: {
                price: "174,99",
                period: "mensal",
                features: [
                    "Pesquisa de Editais",
                    "Agenda de Licitações Integrada",
                    "Sistema de Favoritos",
                    "Acompanhamento em Tempo Real",
                    "Pesquisa de Editais em Múltiplos Portais",
                    "Alertas Diários Personalizados",
                    "Agenda de Licitações Integrada",
                    "Sistema de Favoritos com Notificações para Acompanhar Editais",
                    "Acompanhamento em Tempo Real",
                    "Gerenciamento de Documentos",
                    "Análise de Editais",
                    "Geração Automática de Declarações",
                    "Verificação de Documentos Necessários",
                    "Recomendações Personalizadas Avançadas",
                    "CPH Automático",
                ],
            },
        },
        annual: {
            busca: {
                price: "699,90",
                period: "ano",
                description: "Acesso ao módulo de busca de licitações",
            },
            premium: {
                price: "3.499,90",
                period: "ano",
                description: "Acesso ao módulo de busca de licitações",

            },
            multiEmpresa: {
                price: "1.749,90",
                period: "ano",
                description: "Acesso ao módulo de busca de licitações",

            },
        },
    }


    return (
        <div className="">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-4xl font-semibold text-center text-white mb-8">Escolha de Plano</h1>

                <div className="flex items-center justify-center gap-4 mb-12 w-full text-white">
                    <button className={`py-3 px-5 ${!isAnnual ? "bg-blue-500 bg-opacity-40 border-2 border-blue-500" : "bg-gray-600"} transition-colors rounded-full `} onClick={() => handlePlanChange(false)}>
                        Mensal
                    </button>
                    <button className={`py-3 px-5 ${isAnnual ? "bg-blue-500 bg-opacity-40 border-2 border-blue-500" : "bg-gray-600"}  transition-colors rounded-full  `} onClick={() => handlePlanChange(true)}>
                        Anual
                    </button>
                </div>

                <div className="grid md:grid-cols-3 gap-6 max-w-6xl mx-auto ">
                    {/* Busca Plan */}
                    <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 text-white flex flex-col h-full">
                        <h2 className="text-2xl text-center font-semibold mb-4">Busca</h2>
                        <div className="text-center">
                            <span className="text-4xl font-semibold semi">
                                <span className="text-base">R$</span> {isAnnual ? plans.annual.busca.price : plans.monthly.busca.price}
                                <span className="text-sm">/{isAnnual ? plans.annual.busca.period : plans.monthly.busca.period}</span>
                            </span>
                            {isAnnual ?
                                <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.01 }} className="text-center text-sm w-full p-2 my-2 bg-[#ff4d0081] rounded-m transition-transform">Economize 16,67% (R$ 139,98)</motion.div>
                                : undefined
                            }
                            <p className="text-center text-xs text-gray-100 my-2">Experimente por 15 dias Grátis</p>
                            <p className="text-center underline">Apenas Captação de Licitações</p>

                        </div>
                        <span className="bg-white h-[0.5px] w-full my-5"></span>
                        <div className="flex-grow">
                            <ul className="w-full">
                                {plans.monthly.busca.features.map((feature, index) => (
                                    <li key={index} className="flex flex-col w-full justify-center ">
                                        <div className="grid grid-cols-[auto_1fr] grid-rows-1 items-center gap-2">
                                            <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                            {feature}
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                        </div>
                                    </li>
                                ))}

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>

                                <li className="flex flex-col w-full">
                                    <div className="flex justify-center items-center">
                                        <span className="text-red-500 text-xl">
                                            <RiCloseLargeFill />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <p className="text-center my-4">15 GB em Armazenamento de arquivos</p>
                        <button className="w-full bg-white/20 hover:bg-white/30 text-white rounded-full py-2 px-4 flex items-center justify-center gap-2 transition-colors">
                            <a className="flex items-center justify-center gap-2" href="https://app.portalglc.com/registrar">
                                <p>Contratar</p>
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                                </svg>
                            </a>
                        </button>
                    </div>


                    {/* Premium Plan */}
                    <div
                        className={`bg-white/10 border-2 border-amber-500 transition-colors rounded-2xl p-6 text-white transform flex flex-col h-full`}
                    >
                        <h2 className="text-2xl text-center font-semibold mb-4">Premium</h2>
                        <div className=" text-center">
                            <span className="text-4xl font-semibold transition">
                                <span className="text-base">R$</span> {isAnnual ? plans.annual.premium.price : plans.monthly.premium.price}
                            </span>

                            <span className="text-sm">/{isAnnual ? plans.annual.premium.period : plans.monthly.premium.period}</span>
                        </div>
                        {isAnnual ?
                            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.01 }} className="text-center text-sm w-full p-2 my-2 bg-[#ff4d0081] rounded-m transition-transform">Economize 16,67% (R$ 699,98)</motion.div>
                            : undefined
                        }
                        <p className="text-center text-xs text-gray-100 my-2">Experimente por 15 dias Grátis</p>
                        <p className="text-center underline">1 único CNPJ</p>
                        <span className="bg-white h-[0.5px] w-full my-5"></span>
                        <div className="flex-grow">
                            <ul className="w-full">
                                {plans.monthly.premium.features.map((feature, index) => (
                                    <li key={index} className="flex flex-col w-full justify-center ">
                                        <div className="grid grid-cols-[auto_1fr] grid-rows-1 items-center gap-2">
                                            <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                            {feature}
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        </div>
                        <p className="text-center my-4">15 GB em Armazenamento de arquivos</p>
                        <button className="w-full bg-white/20 hover:bg-white/30 text-white rounded-full py-2 px-4 flex items-center justify-center gap-2 transition-colors">
                            <a className="flex items-center justify-center gap-2" href="https://app.portalglc.com/registrar">
                                <p>Contratar</p>
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                                </svg>
                            </a>
                        </button>
                    </div>


                    {/* Multi-Empresa Plan */}
                    <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 text-white flex flex-col h-full">
                        <h2 className="text-2xl text-center font-semibold mb-4">Multi-Empresas</h2>
                        <div className=" text-center">
                            <span className="text-4xl font-semibold">
                                <span className="text-base">R$</span> {isAnnual ? plans.annual.multiEmpresa.price : plans.monthly.multiEmpresa.price}
                            </span>
                            <span className="text-sm">
                                /{isAnnual ? plans.annual.multiEmpresa.period : plans.monthly.multiEmpresa.period}
                            </span>
                            {isAnnual ?
                                <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.01 }} className="text-center text-sm w-full p-2 my-2 bg-[#ff4d0081] rounded-m transition-transform">Economize 16,67% (R$ 349,98)</motion.div>
                                : undefined
                            }
                            <p className="text-center text-xs text-gray-100 my-2">Experimente por 15 dias Grátis</p>
                            <p className="text-center underline">Acima de 1 CNPJ's!</p>
                        </div>
                        <span className="bg-white h-[0.5px] w-full my-5"></span>
                        <div className="flex-grow">
                            <ul className="w-full">
                                {plans.monthly.multiEmpresa.features.map((feature, index) => (
                                    <li key={index} className="flex flex-col w-full justify-center ">
                                        <div className="grid grid-cols-[auto_1fr] grid-rows-1 items-center gap-2">
                                            <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                            {feature}
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <span className="bg-cyan-900 h-[0.5px] w-full my-5"></span>
                                        </div>
                                    </li>

                                ))}
                            </ul>
                        </div>
                        <p className="text-center my-4">15 GB em Armazenamento de arquivos</p>
                        <button className="w-full bg-white/20 hover:bg-white/30 text-white rounded-full py-2 px-4  transition-colors">
                            <a className="flex items-center justify-center gap-2" href="https://app.portalglc.com/registrar">
                                <p>Contratar</p>
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                                </svg>
                            </a>
                        </button>
                    </div>

                </div>

                <p className="text-center text-white mt-8">
                    {isAnnual
                        ? "Acesse o sistema com um período de teste de 15 dias e decida o pagamento depois"
                        : "Experimente grátis por 15 dias – decida o pagamento depois"}
                </p>
            </div>
        </div >
    )
}

